import React from 'react';
import FloatingSidebarChat from '../components/FloatingSidebarChat';

export default function ChatBots() {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Explora nuestros ChatBots</h1>
      <p>Interactúa con nuestros asistentes inteligentes directamente.</p>
      <FloatingSidebarChat />
    </div>
  );
}
